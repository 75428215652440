import Colors from "../../../theme/colors/colors";

export default {
	container: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		paddingTop: 100,
		justifyContent: 'space-between',
		overflowY: 'auto'
	},
	logo: {
		height: 140
	},
	title: {
		fontFamily: "Gunterz",
		fontSize: 28,
		color: Colors.white,
	},
	middleContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-evenly',
		width: '100%'
	}
};
