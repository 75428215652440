import Colors from "../../../theme/colors/colors";

export default {
	container: {
		alignItems: 'center',
		justifyContent: 'center',
		flex: 1
	},
	topContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	logoImage: {
		width: '80%',
		maxWidth: 920
	},
	sloganImage: {
		width: '50%'
	},
	landingText: {
		fontFamily: 'Gunterz',
		fontSize: 22,
		color: Colors.white,
		marginTop: 24
	}
};
