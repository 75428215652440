import Colors from "../../../theme/colors/colors";

export default {
	formWrapper: {
		display: 'flex',
		flexDirection: 'column'
	},
	input: {
		marginBottom: 24
	},
	passwordForgotContainer: {
		width: '100%',
		//display: 'flex',
		display: 'none',
		justifyContent: 'flex-end'
	},
	passwordForgotLink: {
		color: Colors.white,
		fontSize: 18,
		cursor: 'pointer'
	},
	buttonWrapper: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: 60
	}
};
