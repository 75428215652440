import React, {useEffect, useState} from "react";

import {STRING} from "../../../constants/strings/strings";
import LOGO from "../../../assets/body/logo-cliente.png";

import styles from "./styles";
import "./styles.css";
import Modal from "../../units/Modal/Modal";
import Button from "../../units/Button/Button";
import {getConfig, getLegalTexts} from "../../../data/api/medico-api";
import {saveToSessionStorage} from "../../../constants/auth";
import Colors from "../../../theme/colors/colors";

const Body = ({children, containerStyle = {}, showMeetingText = true, mobile = false}) => {

	const [modal, handleModal] = useState(false);
	const [showLegal, setShowLegal] = useState(sessionStorage.getItem('enable_legal_texts') === "true" ? 'block' : 'none');
	const [legalTexts, setLegalTexts] = useState([]);


	if (mobile) {
		containerStyle = {
			...containerStyle,
			padding: 8,
			margin: 0
		}
	}

	useEffect(() => {
		if (sessionStorage.getItem("enable_legal_texts")) {
			setShowLegal(sessionStorage.getItem("enable_legal_texts") === "true" ? 'block' : 'none');
			return;
		}
		getConfig()
			.then(r => {
				const data = r?.data;
				if (data) {
					saveToSessionStorage(data);
					setShowLegal(data.enable_legal_texts ? 'block' : 'none')
				}
			})
			.catch(err => {
				console.log(err);
			})
	}, [])

	useEffect(() => {
		if (!showLegal) return;
		if (sessionStorage.getItem("legalTexts")) {
			const savedTexts = JSON.parse(sessionStorage.getItem("legalTexts"));
			setLegalTexts(savedTexts);
			return;
		}
		getLegalTexts()
			.then(r => {
				const data = r?.data;
				if (data) {
					const saveData = {
						legalTexts: data
					}
					saveToSessionStorage(saveData);
				}
				setLegalTexts(data);

			})
			.catch(err => {
				sessionStorage.setItem("enable_legal_texts", 'false');
				setShowLegal('none');
				console.log(err);
			})

	}, [showLegal])

	return (<>
			<div className="body-wrapper">
				<div style={{...styles.bodyWidth, ...containerStyle}}>
					{children}
				</div>
			</div>
			<footer style={styles.footerWrapper}>
				<div style={styles.footerInternal}>

					<img style={{height: 20}} src={LOGO} alt={'logo'}/>

					<div style={{...styles.footerLegal, display: showLegal}}>
						<a href="#" className="links" onClick={() => handleModal(true)}
						   style={{width: 100, color: Colors.text}}>Textos legales</a>
					</div>

				</div>
				<Modal isVisible={modal}>
					<div style={styles.modalContent}>
						{legalTexts?.bases_legales && <a target="_blank" href="/legal-bases" className="links"
														 style={{color: 'black'}}>{STRING.LEGAL_BASES}</a>}
						{legalTexts?.aviso_legal && <a target="_blank" href="/legal-warning" className="links"
													   style={{color: 'black'}}>{STRING.LEGAL_WARNING}</a>}
						{legalTexts?.cookies && <a target="_blank" href="/cookies-policy" className="links"
												   style={{color: 'black'}}>{STRING.COOKIES_POLICY}</a>}
						{legalTexts?.privacidad && <a target="_blank" href="/privacy-policy" className="links"
													  style={{color: 'black'}}>{STRING.PRIVACY_POLICY}</a>}
						<br/>
						<Button title={STRING.CLOSE} onClick={() => handleModal(false)}/>
					</div>
				</Modal>

			</footer>
		</>
	)
};

export default Body;
