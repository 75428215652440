import React from "react";
import pjson from "../../../package.json";
import LOGO from "../../assets/landing/logo-landing.png";
import styles from "./styles";
import Body from "../../components/layout/Body/Body";
import Colors from "../../theme/colors/colors";

const NotFound = () => {

	return (
		<Body containerStyle={styles.container}>
			<div style={styles.topContainer}>
				<img src={LOGO} style={styles.logoImage} alt={"Logo"}/>
				<p style={styles.landingText}>ERROR 404</p>
				<p style={styles.landingText}>Ruta no encontrada, comprueba la URL.</p>
				<span
					style={{color: Colors.white, fontSize: 12}}>Versión {pjson.version}</span>
			</div>
		</Body>
	)
};

export default NotFound;
