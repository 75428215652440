import Colors from "../../../theme/colors/colors";

export default {
	container: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		alignItems: 'center',
		paddingTop: 100,
		justifyContent: 'space-around'
	},
	topContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	logo: {
		height: 140
	},
	title: {
		fontFamily: "Gunterz",
		fontSize: 28,
		color: Colors.white,
		marginTop: 56,
		marginBottom: 14
	},
	input: {
		marginBottom: 16
	}
};
