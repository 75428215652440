import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import QRCode from "react-qr-code";

import LOGO from "../../../assets/landing/logo-landing.png";
import {STRING as STRINGS, STRING} from "../../../constants/strings/strings";

import Body from "../../../components/layout/Body/Body";
import Button from "../../../components/units/Button/Button";
import PlayersList from "../../../components/composed/PlayersList/PlayersList";

import styles from "./styles";
import {getMedicosInSessionService} from "../../../data/service/delegadoService";
import moment from "moment";

const WaitingRoom = () => {
	const {meetingType, roomCode} = useParams();
	const navigate = useNavigate();
	const qrUrl = `${STRINGS.PROJECT_URL}/medico/${roomCode}`;
	const textUrl = `${STRINGS.PROJECT_URL}/ medico/${roomCode}`;

	const [medicos, setMedicos] = useState([]);

	const [fetching, setFetching] = useState(false);

	const [lastUpdate, setLastUpdate] = useState("nunca")

	let polling = null;

	const handleOnStartGameClick = () => {
		navigate(`/delegado/affirmations/${meetingType}/${roomCode}`);
	};

	useEffect(() => {
		fetchMedicos()
		if (!polling) polling = setInterval(fetchMedicos, 4000);

		return function cleanup() {
			clearInterval(polling)
		}
	}, [])


	const fetchMedicos = () => {
		setFetching(true);
		getMedicosInSessionService(roomCode)
			.then(r => {
				setMedicos(r)
				setFetching(false);
				setLastUpdate(moment().format("HH:mm:ss"))
			})
			.catch(err => {
				setFetching(false);
			})
	}

	return (
		<Body containerStyle={styles.container}>
			<img style={styles.logo} src={LOGO} alt={"Logo"}/>
			<p style={styles.title}>{STRING.WAITING_ROOM_TITLE}</p>

			<div style={styles.middleContainer}>
				<div>
					<PlayersList players={medicos}/>
					{fetching
						? <p style={{color: "white"}}>Refrescando...</p>
						: <p style={{color: "white"}}>Última actualización: {lastUpdate}</p>
					}
				</div>
				<div style={{textAlign: "center"}}>
					<QRCode
						value={qrUrl}
						level={"H"}
						size={206}
					/>
					<p style={{
						color: 'white',
						maxWidth: 300,
						textAlign: 'center',
						fontWeight: 'bold',
						marginTop: 12
					}}>{textUrl}</p>
				</div>
			</div>
			<div>
				<Button title={STRING.START} onClick={handleOnStartGameClick}
						disable={medicos.length < 1}/>
			</div>
		</Body>
	)
};

export default WaitingRoom;
