import React, {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import {STRING} from "../../../constants/strings/strings";
import LOGO from "../../../assets/landing/logo-landing.png";

import Body from "../../../components/layout/Body/Body";
import styles from "./styles";
import Button from "../../../components/units/Button/Button";
import {loginDelegadoService} from "../../../data/service/medicoService";
import {saveToken} from "../../../constants/auth";
import {saveSessionInfo} from "../../../constants/room";

const LandingPlayer = () => {
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);

	const {roomCode} = useParams();

	const handleStartClick = () => {
		setLoading(true);
		loginDelegadoService(roomCode)
			.then(r => {
				saveToken(r?.token);
				saveSessionInfo(r?.sesion);
				setLoading(false);
				navigate(`/medico/meeting/${roomCode}/register`)
			})
			.catch(err => {
				setLoading(false);
				alert(err);
			})

	}

	return (
		<Body containerStyle={styles.container} showMeetingText={false}>
			<img src={LOGO} style={styles.logoImage} alt={"Logo"}/>
			<div style={styles.mainContainer}>
				<p style={styles.landingText}>{STRING.LANDING_TEXT}</p>
				<Button title={STRING.START} onClick={handleStartClick} loading={loading}/>
			</div>
		</Body>
	)
};

export default LandingPlayer;
