import {getAllSessionsApi, getSessionApi, loginInformesApi} from "../api/informes-api";


export const loginInformesService = (email, password) => {
	return new Promise((resolve, reject) => {
		loginInformesApi(email, password)
			.then(res => {
				if (res.status === 200) {
					const responseData = res.data;
					if (responseData) resolve(responseData);
				} else throw new Error("Error de login");
			})
			.catch(err => {
				console.error(err.response)
				if (err.response) {
					reject(err.response.data?.error);
				}
				reject("Error en el login. Asegúrese que los datos son correctos. Contacte con su responsable si el error persiste.");
			});
	})
};


export const getSessionsService = () => {
	return new Promise((resolve, reject) => {
		getAllSessionsApi()
			.then(res => {
				if (res.status === 200) {
					const responseData = res.data;
					if (responseData) resolve(responseData);
				} else throw new Error("Error obteniendo reuniones");
			})
			.catch(err => {
				console.error(err.response)
				if (err.response) {
					reject(err.response.data?.error);
				}
				reject("Error obteniendo reuniones. Contacte con su responsable si el error persiste.");
			});
	})
};


export const getSessionService = (sessionId) => {
	return new Promise((resolve, reject) => {
		getSessionApi(sessionId)
			.then(res => {
				if (res.status === 200) {
					const responseData = res.data;
					if (responseData) resolve(responseData);
				} else throw new Error("Error obteniendo reuniones");
			})
			.catch(err => {
				console.error(err.response)
				if (err.response) {
					reject(err.response.data?.error);
				}
				reject("Error obteniendo reuniones. Contacte con su responsable si el error persiste.");
			});
	})
};
