import React from "react";

import "./styles.css";

const AffirmationCard = ({content, onClick}) => {
	const {title, subtitle, completed, visible} = content;

	if (!visible) return <></>;

	return (
		<div className={completed ? "container completed" : "container"} onClick={() => onClick()}>
			<p className="container__title">{title}</p>
			<p className="container__text">{subtitle}</p>
		</div>
	)
};

export default AffirmationCard;
