import axiosService from "./axiosService";
import moment from "moment";

export const registerDelegadoApi = (nombre, email, password, acepta_terminos) => {
	const url = "/collections/save/registro_delegados";
	return axiosService.post(url, {data: {nombre, email, password, acepta_terminos}})
};

export const loginDelegadoApi = (email, password) => {
	const url = "/public/auth?login_type=delegado";
	return axiosService.post(url, {email, password})
};

export const createNewRoomApi = (nombre, delegadoId) => {
	const url = "/collections/save/sesiones";
	return axiosService.post(url, {
		data: {
			nombre,
			fecha: moment().format("DD-MM-YYYY"),
			delegado: {
				_id: delegadoId
			}
		}
	})
};


export const getMedicosInSessionApi = (sessionId) => {
	const url = "/collections/get/medicos";
	return axiosService.post(url, {
		"filter": {
			"$and": [
				{
					"sesion._id": sessionId
				}
			]
		}
	})
};


export const getAfirmacionesApi = (afirmacionId = null) => {
	const url = "/collections/get/Afirmaciones";
	let data = {
		"sort": {"orden": 1}
	}
	if (afirmacionId) {
		data = {
			...data,
			filter: {
				_id: afirmacionId
			}
		}
	}
	return axiosService.post(url, data)
};

export const getSessionStatusApi = (sessionId) => {
	const url = "/collections/get/sesiones";
	return axiosService.post(url, {
		filter: {
			_id: sessionId
		},
		populate: 1
	})
};

export const updateSessionAffirmationApi = (sessionId, affirmationId, token) => {
	const url = "/collections/save/sesiones";
	return axiosService.put(url, {
		token,
		data: {
			_id: sessionId,
			afirmacion_activa: {
				_id: affirmationId
			}
		}
	})
}

export const getResponsesSessionAffirmationApi = (sessionId, affirmationId) => {
	const url = "/collections/get/respuestas_medicos";
	return axiosService.post(url, {
		"filter": {
			"$and": [
				{
					"sesion._id": sessionId,
					"afirmacion._id": affirmationId
				}
			]
		}
	})
};
