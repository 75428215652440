import {DOCTOR_INFO, DOCTOR_TOKEN, TOKEN, USER_INFO} from "./constants";

export const saveToken = (token) => {
	localStorage.setItem(TOKEN, token);
}

export const clearToken = () => {
	localStorage.removeItem(TOKEN);
}

export const saveUserInfo = (user) => {
	localStorage.setItem(USER_INFO, JSON.stringify(user))
}

export const getUserInfo = () => {
	return JSON.parse(localStorage.getItem(USER_INFO));
}

export const saveDoctorInfo = doctor => {
	localStorage.setItem(DOCTOR_INFO, JSON.stringify(doctor));
};

export const getDoctorInfo = () => {
	return JSON.parse(localStorage.getItem(DOCTOR_INFO));
};

export const saveDoctorToken = (token) => {
	localStorage.setItem(DOCTOR_TOKEN, token);
};

export const getDoctorToken = () => {
	return localStorage.getItem(DOCTOR_TOKEN)
};

export function saveToSessionStorage(data) {

	// Loop through the keys in the JSON object
	for (const key in data) {
		if (data.hasOwnProperty(key)) {
			// Convert the value to a string
			const valueString = JSON.stringify(data[key]);

			// Save the string to sessionStorage using the key as the key name
			sessionStorage.setItem(key, valueString);
		}
	}
}
