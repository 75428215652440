import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import ReactPlayer from "react-player";
import {STRING} from "../../../constants/strings/strings";
import {getAfirmacionService,} from "../../../data/service/delegadoService";
import Body from "../../../components/layout/Body/Body";
import MainBackground from "../../../components/layout/MainBackground/MainBackground";
import Button from "../../../components/units/Button/Button";

const Video = () => {

	const {meetingType, roomCode, affirmationId} = useParams();
	const location = useLocation();
	const navigate = useNavigate();

	const [fetching, setFetching] = useState(false);

	const [afirmacion, setAfirmacion] = useState([]);


	useEffect(() => {
		fetchData()
	}, [])

	const fetchData = () => {
		setFetching(true);
		getAfirmacionService(affirmationId, roomCode)
			.then(r => {
				console.log(r, 'response');
				setAfirmacion(r)
				setFetching(false);
			})
			.catch(err => {
				setFetching(false);
			})
	}

	const handleBackButton = () => {
		console.log('clicked back button');
	}

	const handleNextButton = () => {
		if (!location?.state?.conclusion) {
			if (meetingType === "meeting") {
				navigate(`/delegado/affirmations/${meetingType}/${roomCode}/${affirmationId}/results`);
			} else if (meetingType === "visit") {
				if (afirmacion.completed) {
					navigate(`/delegado/affirmations/${meetingType}/${roomCode}/${affirmationId}/justification/visita`);
				} else {
					navigate(`/delegado/${meetingType}/${roomCode}/affirmationAnswer/${affirmationId}`);
				}
			}
		} else {
			navigate(`/delegado/affirmations/${meetingType}/${roomCode}`)
		}
	}

	return (
		<Body>
			<MainBackground enableBackButton backButtonPress={handleBackButton} roomCode={roomCode}
							title={afirmacion?.titulo_externo ? afirmacion?.titulo_externo : "CARGANDO..."}
							loading={fetching}>
				<ReactPlayer
					width={'100%'}
					height={'90%'}
					controls={true}
					url={location?.state?.conclusion ? afirmacion?.video_conclusion : afirmacion?.video_intro}/>
				<div style={{display: "flex", justifyContent: "center", padding: 12}}>
					<div style={{maxWidth: 350}}>
						<Button
							title={location.pathname.includes('video_final') ? "Nueva afirmación" : STRING.NEXT}
							onClick={handleNextButton}/>
					</div>
				</div>
			</MainBackground>
		</Body>
	)
};

export default Video;
