import React, {useEffect, useState} from "react";
import Body from "../../components/layout/Body/Body";
import MainBackground from "../../components/layout/MainBackground/MainBackground";
import {saveToSessionStorage} from "../../constants/auth";
import {getLegalTexts} from "../../data/api/medico-api";
import Loader from "../../components/units/loader/Loader";
import {useParams} from "react-router-dom";

const LegalBases = ({children }) => {

	const [pageText, setPageText] = useState("");


	const [isMobile, setIsMobile] = useState(false);

	const {legalType} = useParams();

	function getWindowDimensions() {
		const { innerWidth: width, innerHeight: height } = window;

		setIsMobile(width < 500);
	}

	const setText = (data) => {
		switch (legalType) {
			case "legal-bases":
				setPageText(data.bases_legales);
				break;
			case "legal-warning":
				setPageText(data.aviso_legal);
				break;
			case "cookies-policy":
				setPageText(data.cookies);
				break;
			case "privacy-policy":
				setPageText(data.privacidad);
				break;
			default:
				setPageText("URL no válida, comprueba la URL.");
				break;

		}
	}

	useEffect(() => {

		getWindowDimensions()
		function handleResize() {
			getWindowDimensions()
		}

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		if (children) {
			setPageText(" ")
			return;
		}
		getLegalTexts()
			.then(r => {
				const data = r?.data;
				if (data) {
					const saveData = {
						legalTexts: data
					}
					saveToSessionStorage(JSON.stringify(saveData));
				}
				setText(data);

			})
			.catch(err => {
				setPageText("Error obteniendo los textos legales");
				console.log(err);
			})
	}, [])

	return (
		<Body showMeetingText={false} mobile={isMobile}>
			<MainBackground mobile={isMobile} showLogo={!isMobile}>
				{!pageText && <div style={{textAlign: "center"}}> <Loader color={'green'}/></div>}
				<div dangerouslySetInnerHTML={{__html: pageText}}/>
				{children}
				<p>&nbsp;</p>
				<p>&nbsp;</p>
				<p>&nbsp;</p>
			</MainBackground>
		</Body>
	)
}

export default LegalBases;
