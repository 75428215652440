import Colors from "../../../theme/colors/colors";

export default {
	titleContainer: {
		textAlign: "center",
		width: '100%'
	},
	titleText: {
		fontFamily: 'Gunterz',
		fontSize: 28,
		color: Colors.accent
	},
	titleDoctorInfo: {
		fontSize: 18,
		color: Colors.accent,
		margin: '8px 0'
	},
	questionContainer: {
		backgroundColor: 'white',
		padding: 8
	},
	questionText: {
		fontWeight: 800,
		color: Colors.accent,
		fontSize: 20,
		marginTop: 0,
		marginBottom: 12
	},
	cardsContainer: {
		flex: 1,
		display: "flex",
		gap: 12,
		alignItems: "center",
		justifyContent: 'center',
		paddingBottom: 60
	},
	buttonContainer: {
		position: "absolute",
		bottom: 8,
		left: 0,
		display: "flex",
		justifyContent: "center",
		width: '100%'
	}
};
