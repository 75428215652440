import axiosService from "./axiosService";


export const loginInformesApi = (email, password) => {
	const url = "/public/auth?login_type=reports";
	return axiosService.post(url, {email, password})
};


export const getAllSessionsApi = () => {
	const url = "/public/reports?action=get_sesiones";
	return axiosService.post(url)
};

export const getSessionApi = (sessionId) => {
	const url = "/public/reports?action=get_sesion&session_id=" + sessionId;
	return axiosService.post(url)
};
