import React, {useState} from "react";
import {useNavigate} from "react-router-dom";

import LOGO from "../../../assets/landing/logo-landing.png";
import {STRING} from "../../../constants/strings/strings";

import Body from "../../../components/layout/Body/Body";
import PlayerRegister from "../../../components/composed/PlayerRegister/PlayerRegister";

import styles from "./styles";
import {loginDelegadoService, registerMedicoService} from "../../../data/service/medicoService";
import {saveDoctorInfo, saveDoctorToken} from "../../../constants/auth";
import {getSessionInfo} from "../../../constants/room";

const RegisterVisitPlayer = () => {
	const [formData, setFormData] = useState(null);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const handleOnFormSubmitted = async (event) => {
		event.preventDefault();
		setLoading(true);
		const sessionId = getSessionInfo()?._id;

		try {
			const loginResponse = await loginDelegadoService(sessionId);
			const registerDoctorResponse = await registerMedicoService(formData.name, formData.hospital, sessionId, loginResponse?.token);
			saveDoctorToken(loginResponse?.token);
			saveDoctorInfo(registerDoctorResponse);
			navigate(`/delegado/affirmations/visit/${sessionId}`)
			setLoading(false);

		} catch (error) {
			setLoading(false);
			alert(error);
		}

		/*loginDelegadoService(sessionId)
			.then(response => {
				console.log("LOGIN RESPONSE => ", response)

				registerMedicoService(formData.name, formData.hospital, sessionId, response.token)
					.then(r => {
						// saveUserInfo(r);
						navigate(`/delegado/affirmations/visit/${sessionId}`)
						setLoading(false);
					})
			})
			.catch(err => {
				setLoading(false);
				alert(err);
			})*/
	};

	const handleOnInputChange = event => {
		const newFormData = {...formData, [event.target.name]: event.target.value};
		setFormData(newFormData);

		//scroll to #bottom
		const element = document.getElementById("bottom");
		element.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
	};

	return (
		<Body containerStyle={styles.container}>
			<img src={LOGO} style={styles.logo} alt="Logo"/>
			<p style={styles.title}>{STRING.VISIT}</p>
			<PlayerRegister
				onFormSubmitted={handleOnFormSubmitted}
				onInputChange={handleOnInputChange}
				inputValue={formData}
				loading={loading}
			/>
			<span id={"bottom"} style={{paddingBottom: 10}}/>
		</Body>
	)
}

export default RegisterVisitPlayer;
