import {loginMedico, postAnswerMedicoApi, registerMedicoApi} from "../api/medico-api";
import {getSessionStatusApi} from "../api/delegado-api";

export const loginDelegadoService = (sessionId) => {
	return new Promise((resolve, reject) => {
		loginMedico(sessionId)
			.then(res => {
				if (res.status === 200) {
					const responseData = res.data;
					if (responseData) resolve(responseData);
				} else throw new Error("Error de login");
			})
			.catch(err => {
				console.error(err.response)
				if (err.response) {
					reject(err.response.data?.error);
				}
				reject("Error en el login. Asegúrese que los datos son correctos. Contacte con su responsable si el error persiste.");
			});
	})
};


export const registerMedicoService = (nombre, hospital, sessionId, token) => {
	return new Promise((resolve, reject) => {
		registerMedicoApi(nombre, hospital, sessionId, token)
			.then(res => {
				if (res.status === 200) {
					const responseData = res.data;
					if (responseData) resolve(responseData);
				} else throw new Error("Error de registro");
			})
			.catch(err => {
				console.error(err.response)
				if (err.response) {
					reject(err.response.data?.error);
				}
				reject("Error en el registro. Asegúrese que no se ha registrado ya. Contacte con su responsable si el error persiste.");
			});
	})
};

export const postAnswerMedicoService = (sessionId, affirmationId, medicoId, respuesta, token) => {
	return new Promise((resolve, reject) => {
		postAnswerMedicoApi(sessionId, affirmationId, medicoId, respuesta, token)
			.then(res => {
				if (res.status === 200) {
					const responseData = res.data;
					if (responseData) resolve(responseData);
				} else throw new Error("Error cargando la respuesta");
			})
			.catch(err => {
				console.error(err.response)
				if (err.response) {
					reject(err.response.data?.error);
				}
				reject("Error cargando la respuesta. Compruebe su conexión y Vuelva a intentarlo. Contacte con su responsable si el error persiste.");
			});
	})
};

export const getSessionStatusService = (sessionId) => {
	return new Promise((resolve, reject) => {
		getSessionStatusApi(sessionId)
			.then(res => {
				if (res.status === 200) {
					const responseData = res.data;
					if (responseData) resolve(responseData?.entries);
				} else throw new Error("Error obteniendo estado de sesión");
			})
			.catch(err => {
				console.error(err.response)
				if (err.response) {
					reject(err.response.data?.error);
				}
				reject("Error obteniendo estado de sesión. Contacte con su responsable si el error persiste.");
			});
	})
}
