import React from "react";

import "./styles.css";

const Modal = ({children, isVisible = false}) => {
	return isVisible &&
		(
			<div className="modal-background">
				<div className="modal-container">
					<div className="modal-content">
						{children}
					</div>
				</div>
			</div>
		)
};

export default Modal;
