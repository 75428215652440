import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import LOGO from "../../../assets/landing/logo-landing.png";
import {STRING} from "../../../constants/strings/strings";

import Body from "../../../components/layout/Body/Body";
import FormRegister from "../../../components/composed/FormRegister/FormRegister";

import styles from "./styles";
import {registerDelegadoService} from "../../../data/service/delegadoService";
import Button from "../../../components/units/Button/Button";
import Modal from "../../../components/units/Modal/Modal";
import {clearToken} from "../../../constants/auth";

const Register = () => {
	const [formData, setFormData] = useState(null);
	const [checkboxValue, setCheckboxValue] = useState(false);
	const navigate = useNavigate();

	const [modal, setModal] = useState(false);

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		clearToken()
	}, [])

	const handleOnFormSubmitted = event => {
		event.preventDefault();

		if (!validatePasswords(formData?.password, formData?.repeatPassword)) {
			alert("La contraseña no coincide");
			return;
		}

		setLoading(true);

		registerDelegadoService(formData.name, formData.email, formData.password, formData.checkbox)
			.then(r => {
				setModal(true);
				setLoading(false);
			})
			.catch(err => {
				setLoading(false);
				alert(err);
			})

	};

	const handleModal = (goToHome = false) => {
		setModal(false)
		if (goToHome) navigate("/delegado");
	}

	const handleOnInputChange = event => {
		const newFormData = {...formData, [event.target.name]: event.target.value};
		setFormData(newFormData);
	};

	const handleOnCheckboxChange = event => {
		setFormData({...formData, [event.target.name]: event.target.checked})
		setCheckboxValue(event.target.checked)
	};

	const validatePasswords = (password, repeatedPassword) => {
		return password === repeatedPassword
	};

	return (
		<>
			<Body containerStyle={styles.container}>
				<img src={LOGO} style={styles.logo} alt="Logo"/>
				<p style={styles.title}>{STRING.REGISTER_TITLE}</p>
				<FormRegister
					onFormSubmitted={handleOnFormSubmitted}
					onInputChange={handleOnInputChange}
					onCheckboxChange={handleOnCheckboxChange}
					inputValue={formData}
					checkboxValue={checkboxValue}
					loading={loading}
				/>
			</Body>
			<Modal isVisible={modal}>
				<div>
					<div style={styles.modalContent}>
						<p style={styles.errorTitle}>{"Registro correcto"}</p>
						<Button title={STRING.CLOSE} onClick={() => handleModal(true)}/>
					</div>
				</div>
			</Modal>
		</>
	)
};

export default Register;
