import React, {useState} from "react";

import LOGO_INTERNO from "../../../assets/landing/logo-interno.png";

import "./styles.css";
import Loader from "../../units/loader/Loader";
import QRCode from "react-qr-code";
import Button from "../../units/Button/Button";
import BackButton from "../../units/BackButton/BackButton";
import {STRING as STRINGS} from "../../../constants/strings/strings";

const MainBackground = ({
							backButtonPress,
							title = "",
							children,
							showLogo = true,
							loading = false,
							mobile = false,
							roomCode = null,
							enableBackButton = false
						}) => {

	const qrUrl = `${STRINGS.PROJECT_URL}/medico/${roomCode}`;
	const textUrl = `${STRINGS.PROJECT_URL}/ medico/${roomCode}`;
	const [open, setOpen] = useState(false)

	const toggleModal = () => {
		//if url does contain visit, dont show qr
		if (window.location.href.includes("/visit/")) return;
		setOpen(!open);
	}

	return (
		<>
			{showLogo &&
				<img onClick={() => toggleModal()} className="background__logo" src={LOGO_INTERNO}
					 alt={"Logo"}/>}
			<div className="background" style={mobile ? {margin: 0, borderRadius: 8} : {}}>
				{!mobile && <div className="background__title-container">
					{enableBackButton && <BackButton handleBackButton={backButtonPress}/>}
					<p className={`background__title-text ${!enableBackButton && "titleOnly"}`}>{title}</p>
				</div>}
				<div className="background__container" style={mobile ? {padding: 8} : {}}>
					{loading &&
						<div style={{width: '100%', textAlign: "center"}}><Loader color={"green"}/>
						</div>}
					{children}
				</div>
				{open &&
					<div onClick={() => setOpen(false)} className="modal-container-qr">
						<div className="modal-content-qr">
							<QRCode
								value={qrUrl}
								level={"H"}
								size={206}
							/>
							<br/>
							<p className="link-qr">{textUrl}</p>
							<div>
								<Button title={'Cerrar'} onClick={() => setOpen(false)}/>
							</div>
						</div>
					</div>
				}
			</div>
		</>
	)
};

export default MainBackground;
