import React, {useEffect, useState} from "react";

import {STRING} from "../../../constants/strings/strings";

import Body from "../../../components/layout/Body/Body";
import MainBackground from "../../../components/layout/MainBackground/MainBackground";
import AffirmationCard from "../../../components/units/AffirmationCard/AffirmationCard";

import styles from "./styles";
import {getAfirmacionesInSessionService} from "../../../data/service/delegadoService";
import {useNavigate, useParams} from "react-router-dom";
import Colors from "../../../theme/colors/colors";

import "./styles.css";

const Affirmations = () => {

	const {meetingType, roomCode} = useParams();

	const navigate = useNavigate();

	const [fetching, setFetching] = useState(true);

	const [afirmaciones, setAfirmaciones] = useState([]);

	let polling = null;

	useEffect(() => {
		fetchData()
		if (!polling) polling = setInterval(fetchData, 4000);

		return function cleanup() {
			clearInterval(polling)
		}
	}, [])

	const handleClickAffirmation = (affirmationId) => {
		navigate(`/delegado/affirmations/${meetingType}/${roomCode}/${affirmationId}/video`);
	}

	const fetchData = () => {
		setFetching(true);
		getAfirmacionesInSessionService(roomCode)
			.then(r => {
				console.log(r);
				setAfirmaciones(r)
				setFetching(false);
			})
			.catch(err => {
				setFetching(false);
			})
	}

	return (
		<Body>
			<MainBackground roomCode={roomCode}
							loading={fetching && Object.keys(afirmaciones).length === 0}>
				<h2 className={"title"} style={{
					fontFamily: 'Helvetica',
					textAlign: "center",
					fontSize: 32,
					letterSpacing: '0.15em',
					color: Colors.accent
				}}>{STRING.AFFIRMATIONS}</h2>
				<div style={styles.mainContainer}>
					{Object.keys(afirmaciones).map((category, index) =>
						<>
							<h3 style={{marginBottom: 8, color: Colors.primary, fontSize: 18, textTransform: "uppercase", fontWeight: "700", fontFamily: "Noto Sans"}}>{afirmaciones[category]?.name}</h3>
							<div style={styles.container}>
								{afirmaciones[category]?.affirmations?.map((item, index) =>
									<AffirmationCard key={index} content={item}
													 onClick={() => handleClickAffirmation(item._id)}/>)}
							</div>
						</>
					)}
				</div>
			</MainBackground>
		</Body>
	)
};

export default Affirmations;
