import React, {useEffect, useState} from "react";
import Body from "../../../components/layout/Body/Body";
import {STRING} from "../../../constants/strings/strings";
import styles from "./styles";
import LOGO_INTERNO from "../../../assets/landing/logo-interno.png";
import SLOGAN from "../../../assets/landing/landing-slogan.png";
import Lottie from "lottie-react";
import eye from "../../../assets/lottie/138089-heart.json";
import {getSessionStatusService} from "../../../data/service/medicoService";
import {saveSessionInfo} from "../../../constants/room";
import {useNavigate, useParams} from "react-router-dom";


const PlayerWaitingRoom = () => {

	const {roomCode} = useParams();

	const navigate = useNavigate();

	let polling = null;

	const [fetching, setFetching] = useState(true);


	useEffect(() => {
		fetchData()
		if (!polling) polling = setInterval(fetchData, 4000);

		return function cleanup() {
			clearInterval(polling)
		}
	}, [])

	const fetchData = () => {
		setFetching(true);
		getSessionStatusService(roomCode)
			.then(r => {
				saveSessionInfo(r[0]);
				setFetching(false);
				const activeAffirmationId = r[0]?.afirmacion_activa?._id;
				if (activeAffirmationId) {
					navigate(`/medico/meeting/${roomCode}/affirmationAnswer/${activeAffirmationId}`)
				}
			})
			.catch(err => {
				setFetching(false);
				alert(err);
			})

	}

	return (
		<Body showMeetingText={false}>
			<div style={styles.mainContainer}>
				<img src={LOGO_INTERNO} style={styles.logo} alt="Logo"/>
				<img src={SLOGAN} style={styles.sloganImage} alt={"Slogan"}/>
				<p style={styles.title}>{STRING.WAITING_MESSAGE}</p>
				<Lottie animationData={eye} style={{width: 150, marginBottom: -40}} loop={true}/>
			</div>
		</Body>
	)
}

export default PlayerWaitingRoom;
