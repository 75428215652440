import React from "react";
import pjson from "../../../package.json";
import LOGO from "../../assets/landing/logo-landing.png";
import styles from "./styles";
import Body from "../../components/layout/Body/Body";
import Colors from "../../theme/colors/colors";

const Home = () => {

	return (
		<Body containerStyle={styles.container}>
			<div style={styles.topContainer}>
				<img src={LOGO} style={styles.logoImage} alt={"Logo"}/>
				<br/>
				<p style={styles.landingText}>Accede como delegado o como médico usando el enlace
					que te han
					proporcionado.</p>
				<span
					style={{color: Colors.white, fontSize: 12}}>Versión {pjson.version}</span>
			</div>
		</Body>
	)
};

export default Home;
