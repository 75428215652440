import React from "react";

import styles from "./styles";

const PlayerListItem = ({username, workplace}) => {
	return (
		<div style={styles.container}>
			<p style={styles.username}>{username}</p>
			<p style={styles.dot}>·</p>
			<p style={styles.workplace}>{workplace}</p>
		</div>
	)
};

export default PlayerListItem;
