export default {
	formWrapper: {
		display: 'flex',
		flexDirection: 'column'
	},
	input: {
		marginBottom: 24
	},
	buttonWrapper: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: 32
	}
}
