import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import LOGO from "../../../assets/landing/logo-landing.png";
import {STRING} from "../../../constants/strings/strings";

import Body from "../../../components/layout/Body/Body";
import FormLogin from "../../../components/composed/FormLogin/FormLogin";
import Modal from "../../../components/units/Modal/Modal";
import Button from "../../../components/units/Button/Button";

import styles from "./styles";
import {loginDelegadoService} from "../../../data/service/delegadoService";
import {clearToken, saveToken, saveUserInfo} from "../../../constants/auth";
import {loginInformesService} from "../../../data/service/informesService";

const Login = ({type}) => {
	const [formData, setFormData] = useState(null);
	const [openErrorModal, setOpenErrorModal] = useState(false);
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		clearToken()
	}, [])

	const handleOnInputChange = event => {
		const newFormData = {...formData, [event.target.name]: event.target.value};
		setFormData(newFormData);
	};

	const handleOnFormSubmitted = event => {
		event.preventDefault();
		setLoading(true);
		if (type === "informes") {
			loginInformesService(formData.email, formData.password)
				.then(r => {
					console.log("r", r);
					saveToken(r?.token);
					saveUserInfo(r?.delegado);
					setLoading(false);
					navigate("/informes/list");
				})
				.catch(err => {
					setLoading(false);
					setOpenErrorModal(true);
				})
		} else {
			loginDelegadoService(formData.email, formData.password)
				.then(r => {
					console.log("r", r);
					saveToken(r?.token);
					saveUserInfo(r?.delegado);
					setLoading(false);
					navigate("/delegado/selector");
				})
				.catch(err => {
					setLoading(false);
					setOpenErrorModal(true);
				})
		}
	};

	const handleOnCloseErrorModal = () => {
		setOpenErrorModal(false);
	};

	const handleOnPasswordForgotClick = () => {
		//No es farà
	};

	return (
		<Body containerStyle={styles.container}>
			<img style={styles.logo} src={LOGO} alt={"Logo"}/>
			<p style={styles.title}>{STRING.LOGIN_TITLE}</p>
			<FormLogin
				onInputChange={handleOnInputChange}
				inputValue={formData}
				onFormSubmitted={handleOnFormSubmitted}
				onPasswordForgotClick={handleOnPasswordForgotClick}
				loading={loading}
			/>
			<Modal isVisible={openErrorModal}>
				<div>
					<div style={styles.closeModalIconContainer}>
						<p onClick={handleOnCloseErrorModal} style={styles.closeModalIcon}>+</p>
					</div>
					<div style={styles.modalContent}>
						<p style={styles.errorTitle}>{STRING.LOGIN_ERROR_TITLE}</p>
						<p style={styles.errorMsg}>{STRING.LOGIN_ERROR_MSG}</p>
						<Button title={STRING.CLOSE} onClick={handleOnCloseErrorModal}/>
					</div>
				</div>
			</Modal>
		</Body>
	)
};

export default Login;
