export default {
	white: "#FFFFFF",
	primary: "#E5077E",
	accent: "#0178B4",
	completed: "#C3E7B7",
	text: "#525252",
	davyGray: "#575757",
	disabledAccent: "rgb(77,93,103)",
	silverSand: "#C4C4C4",
	silverBlue: "#4C687B",
	green: '#60BD40',
	red: '#ae2f2f'
}
