export const afirmacionesFormatterFromApi = (afirmaciones, sessionStatus) => {
	const res = [];
	for (let afirmacion of afirmaciones) {
		const category = afirmacion.categoria;
		if (res[category]?.affirmations) {
			res[category].affirmations.push({
				...afirmacion,
				title: afirmacion.titulo_externo,
				subtitle: afirmacion.subtitulo_externo,
				completed: sessionStatus?.afirmaciones_realizadas?.some(af => af._id === afirmacion._id)
			})
		} else {
			res[category] = {
				name: category,
				affirmations: [{
					...afirmacion,
					title: afirmacion.titulo_externo,
					subtitle: afirmacion.subtitulo_externo,
					completed: sessionStatus?.afirmaciones_realizadas?.some(af => af._id === afirmacion._id)
				}
				]
			}
		}
	}
	return res;
}

export const getAfirmacionFormatter = (afirmacion, sessionStatus) => {

	return {
		...afirmacion,
		completed: sessionStatus?.afirmaciones_realizadas?.some(af => af._id === afirmacion._id)
	}

}
