import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {getAfirmacionService,} from "../../../data/service/delegadoService";
import Body from "../../../components/layout/Body/Body";
import MainBackground from "../../../components/layout/MainBackground/MainBackground";
import Button from "../../../components/units/Button/Button";
import fullscreen from "../../../assets/fullscreen.png";
import exitFullscreen from "../../../assets/exit-fullscreen.png";
import SimpleImageSlider from "react-simple-image-slider";
import Colors from "../../../theme/colors/colors";

const Justification = () => {

	const {meetingType, roomCode, affirmationId, justificationNum} = useParams();

	const [isFullscreen, setIsFullscreen] = useState(false);

	const [slides, setSlides] = useState([]);

	const isVideo = window.location.href.includes('video');

	const navigate = useNavigate();

	const [fetching, setFetching] = useState(false);

	const [afirmacion, setAfirmacion] = useState([]);

	const handleBackButton = () => {
		console.log('clicked back button');
	}


	useEffect(() => {
		fetchData()
	}, [])

	const fetchData = () => {
		setFetching(true);
		getAfirmacionService(affirmationId, roomCode)
			.then(r => {
				console.log(r);
				setAfirmacion(r)
				const justificaciones = meetingType === "visit" ? r.justificacion_visita : r.justificaciones;
				const images = [];
				justificaciones?.forEach((justificacion) => {
					if (justificacion.path[0] === '/') images.push({url: justificacion.path})
					else images.push({url: "/" + justificacion.path})
				})
				console.log(images)
				setSlides(images);
				setFetching(false);
			})
			.catch(err => {
				setFetching(false);
			})
	}

	const handleNextButton = () => {
		if (isVideo) {
			if (meetingType === "meeting") {
				navigate(`/delegado/affirmations/${meetingType}/${roomCode}/${affirmationId}/results`);
			} else if (meetingType === "visit") {
				if (afirmacion.completed) {
					navigate(`/delegado/affirmations/${meetingType}/${roomCode}/${affirmationId}/justification/visita`);
				} else {
					navigate(`/delegado/${meetingType}/${roomCode}/affirmationAnswer/${affirmationId}`);
				}
			}
			return;
		}

		navigate(`/delegado/affirmations/${meetingType}/${roomCode}/${affirmationId}/video_final`, {state: {conclusion: true}});

	}

	const renderSlider = () => {
		if (slides.length > 0) {
			return <>
				<div className={isFullscreen ? "slider-fullscreen" : 'slider'}>
					<SimpleImageSlider
						width={'100%'}
						loop={false}
						navSize={60}
						navStyle={2}
						navigationColor={Colors.primary}
						height={'100%'}
						style={{minHeight: 500, position: 'relative'}}
						images={slides}
						showBullets={false}
						showNavs={true}
					/>
					{isFullscreen ?
						<div style={{position: "absolute", zIndex: 50, bottom: 18, right: 18}}>
							<img style={{"width": "40px", "height": "40px", cursor: "pointer"}}
								 src={exitFullscreen}
								 onClick={() => setIsFullscreen(false)}/>
						</div>
						:
						<div style={{
							position: "absolute",
							zIndex: 50,
							top: 'calc(100% - 60px)',
							right: 18
						}}>
							<img style={{"width": "40px", "height": "40px", cursor: "pointer"}}
								 src={fullscreen}
								 onClick={() => setIsFullscreen(true)}/>
						</div>
					}
				</div>
			</>
		}
		return <span></span>
	}

	return (
		<Body>
			<MainBackground enableBackButton backButtonPress={handleBackButton} roomCode={roomCode}
							title={afirmacion?.titulo_externo ? afirmacion?.titulo_externo : "CARGANDO..."}
							loading={fetching}>
				{isVideo
					? <h1 className="background__title-text"
						  style={{
							  fontSize: 34,
							  textAlign: "center",
							  padding: 16
						  }}>{afirmacion.pregunta}</h1>
					: <>
						<p className="background__title-text"
						   style={{textAlign: "center", marginBottom: 12}}>JUSTIFICACIÓN</p>
						<div style={{display: "flex", justifyContent: "center"}}>
							{renderSlider()}
						</div>
					</>
				}
				<div style={{display: "flex", justifyContent: "center", padding: 12}}>
					<div style={{maxWidth: 350}}>
						<Button title={'Siguiente'} onClick={handleNextButton}/>
					</div>
				</div>
			</MainBackground>
		</Body>
	)
};

export default Justification;
