import React, {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import LOGO_INTERNO from "../../../assets/landing/logo-interno.png";
import {STRING} from "../../../constants/strings/strings";

import Body from "../../../components/layout/Body/Body";

import styles from "./styles";
import PlayerRegister from "../../../components/composed/PlayerRegister/PlayerRegister";
import SLOGAN from "../../../assets/landing/landing-slogan.png";
import {registerMedicoService} from "../../../data/service/medicoService";
import {saveUserInfo} from "../../../constants/auth";

const RegisterPlayer = () => {

	const {roomCode} = useParams();

	const [formData, setFormData] = useState(null);
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);

	const handleOnFormSubmitted = event => {
		event.preventDefault();
		setLoading(true);
		registerMedicoService(formData.name, formData.hospital, roomCode)
			.then(r => {
				saveUserInfo(r);
				navigate(`/medico/meeting/${roomCode}/waiting-room`)
				setLoading(false);
			})
			.catch(err => {
				setLoading(false);
				alert(err);
			})
	};

	const handleOnInputChange = event => {
		const newFormData = {...formData, [event.target.name]: event.target.value};
		setFormData(newFormData);
	};

	return (
		<Body showMeetingText={false} containerStyle={styles.container}>
			<img src={LOGO_INTERNO} style={styles.logo} alt="Logo"/>
			<img src={SLOGAN} style={styles.sloganImage} alt={"Slogan"}/>
			<p style={styles.title}>{STRING.ABOUT_YOU}</p>
			<PlayerRegister
				onFormSubmitted={handleOnFormSubmitted}
				onInputChange={handleOnInputChange}
				inputValue={formData}
				loading={loading}
			/>
		</Body>
	)
};

export default RegisterPlayer;
