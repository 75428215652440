import React from "react";

import styles from "./styles";
import Input from "../../units/Input/Input";
import {STRING} from "../../../constants/strings/strings";
import Button from "../../units/Button/Button";

const FormLogin = ({
					   onFormSubmitted,
					   onInputChange,
					   inputValue,
					   onPasswordForgotClick,
					   loading
				   }) => {
	return (
		<form style={styles.formWrapper} onSubmit={onFormSubmitted}>
			<Input
				name="email"
				placeholder={STRING.EMAIL}
				onChange={onInputChange}
				value={inputValue?.email}
				type={"text"}
				customStyle={styles.input}
			/>
			<Input
				name="password"
				placeholder={STRING.PASSWORD}
				onChange={onInputChange}
				value={inputValue?.password}
				type={"password"}
				customStyle={styles.input}
			/>
			<div style={styles.passwordForgotContainer}>
				<p style={styles.passwordForgotLink}
				   onClick={onPasswordForgotClick}>{STRING.PASSWORD_FORGOT}</p>
			</div>
			<div style={styles.buttonWrapper}>
				<Button title={STRING.LOGIN_BUTTON} loading={loading}/>
			</div>
		</form>
	)
};

export default FormLogin;
